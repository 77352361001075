import Vue from 'vue';
import Vuex from 'vuex';

import bookShoot from './modules/new-shoot';
import error from './modules/errors';
import infiniteScroll from './modules/infiniteScroll';
import notification from './modules/notifications';
import pkg from './modules/packages';
import services from './modules/services';
import settings from './modules/settings';
import shoot from './modules/shoot';
import stepper from './modules/stepper';
import user from './modules/user';
import alert from './modules/alert';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    bookShoot,
    error,
    infiniteScroll,
    notification,
    pkg,
    services,
    settings,
    shoot,
    stepper,
    user,
    alert
  }
});
