import instance, { getUri } from '../index';
import store from '../../store/index';

// Utils
import { CANCELED } from '@utils/shootsHelpers';

const RESOURCE_ACTION = 'action';
const RESOURCE_SHOOT = 'shoot';
const RESOURCE_SHOOTS = 'shoots';
const RESOURCE_SEARCH = 'search';

export const ShootsApi = {
  /**
   * Assign photographer
   */
  assignPhotographer({ shoot_id, photographer_id }) {
    return instance.post(`${RESOURCE_SHOOT}/${shoot_id}/${RESOURCE_ACTION}/assign-photographer`, {
      photographer_id
    });
  },
  /**
   * Method used to cancel a shoot. It will call the updateStatusApi with 'CANCELED' as status
   */
  cancel(id) {
    return this.updateStatus({
      id,
      payload: {
        targetStatus: CANCELED
      }
    });
  },
  /**
   * Api call to create a new shoot
   */
  createShoot({ payload }) {
    const payloadClone = JSON.parse(JSON.stringify(payload));
    const type = payloadClone.type;

    if (type === 'custom') {
      payloadClone.is_payed = true;
    } else if (type === 'express') {
      let price = 0;
      let revenue = 0;

      payloadClone.packages.forEach(element => {
        price += Number(element.price);
        revenue += Number(element.photographer_revenue);

        delete element.duration;
        delete element.price;
        delete element.photographer_revenue;
        delete element.picture_number;
        delete element.name;
        delete element.service_id;
      });

      delete payloadClone.total_price;
      delete payloadClone.total_revenue;

      if (store.getters['user/isAdmin']) {
        payloadClone.total_price = price;
        payloadClone.total_revenue = revenue;

        payloadClone.photographer_id = null;
      }
    }

    payloadClone.type = type;

    return instance.post(`/${RESOURCE_SHOOT}`, payloadClone);
  },
  /**
   * Api call to download shoot brief
   */
  downloadBrief({ id }) {
    const uri = getUri({ url: `${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/download-brief` });
    window.open(uri, 'Download');
  },
  /**
   * Api call to download flashy edited photo
   */
  downloadProcessed({ id }) {
    const uri = getUri({ url: `${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/download-processed` });
    window.open(uri, 'Download');
  },
  /**
   * Api call to download flashy edited photo
   */
  downloadRaw({ id }) {
    const uri = getUri({ url: `${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/download-raw` });
    window.open(uri, 'Download');
  },
  /**
   * Api call to edit shoot
   */
  editShoot({ id, payload }) {
    return instance.post(`${RESOURCE_SHOOT}/edit/${id}`, payload);
  },
  updatePreview({ id, payload }) {
    return instance.post(`/shoot/${id}/action/update-preview`, payload);
  },
  updateRawsPreview({ id, payload }) {
    return instance.post(`/shoot/${id}/action/update-raws-preview`, payload);
  },
  /**
   * Get shoots search filters
   */
  getFilters() {
    return instance.get(`/${RESOURCE_SHOOT}/${RESOURCE_ACTION}/get-shoots-filters`);
  },
  /**
   * Api call to schedule shoot
   */
  scheduleShoot({ id, payload }) {
    return instance.post(`${RESOURCE_SHOOT}/schedule/${id}`, payload);
  },
  searchShoots(payload) {
    return instance.post(`/${RESOURCE_SHOOTS}/${RESOURCE_SEARCH}`, payload);
  },
  /**
   * Api call to remove a photographer from a given shoot
   * @param {Number} id shoot id
   * @returns Api call response
   */
  unassignPhotographer(id) {
    return instance.post(`/${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/unassign-photographer`);
  },
  updateStatus({ id, payload }) {
    return instance.post(`/${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/update-status`, payload);
  },
  updateImageStatus({ id, payload }) {
    return instance.post(`/image/${id}/action/update-status`, payload);
  },
  updateRawImageStatus({ id, payload }) {
    return instance.post(`/raw/${id}/action/update-status`, payload);
  },
  bulkApproveImages({ payload }) {
    return instance.post('/image/action/bulk-approve', payload);
  },
  approveRemainingImages({ payload }) {
    return instance.post('/image/action/approve-remaining', payload);
  },

  bulkSelectRaws({ payload }) {
    return instance.post('/raw/action/bulk-select', payload);
  },
  /**
   * Api call to updload the shoot raw photos (photographer uploading shoots)
   */
  uploadBrief({ id, blob }) {
    return instance.post(`${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/upload-brief`, blob, {
      headers: {
        'content-type': 'application/octet-stream'
      }
    });
  },
  /**
   * Upload processed photos
   */
  uploadProcessedOld({ id, blob, onUploadProgress }) {
    return instance.post(`${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/upload-processed`, blob, {
      headers: {
        'Content-Type': 'application/octet-stream'
      },
      onUploadProgress
    });
  },

  /**
   * Upload processed photos
   */
  uploadProcessed({ id, blob, onUploadProgress }) {
    return instance.post(`${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/upload-jpeg-preview`, blob, {
      headers: {
        'Content-Type': 'application/octet-stream'
      },
      onUploadProgress
    });
  },
  /**
   * Upload processed photos
   */
  uploadRawsPreview({ id, blob, onUploadProgress }) {
    return instance.post(`${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/upload-raws-preview`, blob, {
      headers: {
        'Content-Type': 'application/octet-stream'
      },
      onUploadProgress
    });
  },
  /**
   * Api call to updload the shoot raw photos (photographer uploading shoots)
   */
  uploadRaw({ id, blob, onUploadProgress }) {
    return instance.post(`${RESOURCE_SHOOT}/${id}/${RESOURCE_ACTION}/upload-raw`, blob, {
      headers: {
        'Content-Type': 'application/octet-stream'
      },
      onUploadProgress
    });
  },

  getShootById({ shoot_id }) {
    return instance.get(`/${RESOURCE_SHOOT}/${shoot_id}`);
  }
};
